import { styled } from '@mui/system'
import { Link } from 'gatsby'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO title="404 Not Found" description="" page="404" />
)

const NotFoundPage = () => {
    return (
        <Layout>
            <Wrapper>
                <h1>
                    <Txt size="l">ご指定のページが見つかりません</Txt>
                </h1>
                <p>
                    ご指定のページは削除されたか、移動した可能性がございます。
                    <br />
                    <Link to="/">トップページに戻る</Link>.
                </p>
            </Wrapper>
        </Layout>
    )
}

const Wrapper = styled('div')({
    padding: '20px',
})

export default NotFoundPage
